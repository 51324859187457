
table, tr, td {
  font-size: medium;
}

th {
  width: 150px;
  background-color: black;
  padding: 5px;
}
